import React, {useState} from "react"
import {Button, Col, Container, Row, Modal} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUpModal from "../components/sign-up-modal"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import {FaQuoteLeft, FaQuoteRight, FaRegCheckCircle, FaStar, FaStarHalfAlt} from "react-icons/fa";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";
import {HiOutlineBadgeCheck} from "react-icons/hi";

export const query = graphql`
    query Circles2 {
        anxiety: file(base: { eq: "anxiety-relief.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        learning: file(relativePath: { eq: "learning-ladie.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cycle: file(relativePath: { eq: "cbt-cycle.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`

const Circles = ({ data, pageContext }) => {
	const [showCalendly, setShowCalendly] = useState(false);
	const [userData, setUserData] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const intl = useIntl();
	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);
	const handleSuccess = (data) => {
		setUserData(data);
		closeModal();
		setShowCalendly(true);
		return Promise.resolve();
	}
	return (
		<Layout hideSignUp>
			<SEO
				title={intl.formatMessage({ id: "hot-flashes.title", defaultMessage: "Reduce anxiety and hot flashes" })}
				description={intl.formatMessage({ id: "hot-flashes.description", defaultMessage: "Menopause on your terms."})}
			/>
			<section className="bg-light pb-5 mb-5">
				<Container className="py-md-5">
					<Row className="align-items-center">
						<Col xs="12" md="6" className="order-1 order-md-0">
							<h1>
								<FormattedMessage id="hot-flashes.h1" defaultMessage="Transform Anxiety and Hot Flashes into Resilience" />
							</h1>
							<div className="mb-5" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "hot-flashes.p", defaultMessage: "Get started with our 7-day anxiety and hot flashes relief program, providing you with practical tools that can be incorporated into your daily habits with immediate effect."}) }}/>
							<div className="d-flex align-items-center">
								<Button onClick={openModal} variant="primary" size="lg" className="mr-3"><FormattedMessage id="hot-flashes.join-button" defaultMessage="Start Today - It's Free" /></Button>
							</div>
						</Col>
						<Col xs="12" md="6" className="d-flex justify-content-center mb-4 mb-md-0 order-0 order-md-1">
							<Img className="w-100" fluid={data?.anxiety?.childImageSharp?.fluid}/>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<h1 className="text-center pb-4"><FormattedMessage id="hot-flashes.why-join" defaultMessage="What does the program teach?" /></h1>
					<Row className="mb-5">
						<Col>
							<p className="text-center">The program will guide you through a set of evidence-based methods that will help you cope with anxiety and hot flashes.</p>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6" className="d-flex justify-content-center mb-3">
							<Img className="w-75" fluid={data?.learning?.childImageSharp?.fluid}/>
						</Col>
						<Col xs="12" md="6" className="d-flex align-items-center">
							<ul className="list-unstyled h5">
								<li className="d-flex mb-4">
									<div><FaRegCheckCircle className="mr-2 text-success" /></div>
									<div>
										<b>Resilient thoughts</b>
										<div className="font-weight-light">Learn to catch, check, collect, challenge, and change worrisome, negative, or looping thoughts.</div>
									</div>
								</li>
								<li className="d-flex mb-4">
									<div><FaRegCheckCircle className="mr-2 text-success" /></div>
									<div><b>Mindfulness</b><div className="font-weight-light">Discover how to go from asking constant "What if" questions to coming back to "What is" going on in the present moment.</div></div>
								</li>
								<li className="d-flex mb-4">
									<div><FaRegCheckCircle className="mr-2 text-success" /></div>
									<div><b>Emotional Intelligence</b><div className="font-weight-light">Dive into your inner emotional world to expand self-awareness of emotions, what they mean, and how to navigate them.</div></div>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5" id="how-it-works">
				<Container>
					<div className="text-center pb-4 h1"><FormattedMessage id="hot-flashes.how-it-works.title" defaultMessage="How it works" /></div>
					<Row className="pt-3">
						<Col xs="12" md="4" className="mb-3">
							<div className="d-flex justify-content-center"><div className="number-circle number-circle-large">1</div></div>
							<h3 className="text-center mt-3"><FormattedMessage id="hot-flashes.how-it-works.cols.1.title" defaultMessage="Learn" /> </h3>
							<div className="mb-2">We send you an email every day with our resilience-building techniques and exercises that you need to follow.</div>
						</Col>
						<Col xs="12" md="4" className="mb-3">
							<div className="d-flex justify-content-center"><div className="number-circle number-circle-large">2</div></div>
							<h3 className="text-center mt-3"><FormattedMessage id="hot-flashes.how-it-works.cols.2.title" defaultMessage="Practice" /> </h3>
							<p>
								<FormattedMessage id="hot-flashes.how-it-works.cols.2.paragraph" defaultMessage="You practice coping skills from the program in real-life situations. Practice develops resilient habits." />
							</p>
						</Col>
						<Col xs="12" md="4" className="mb-3">
							<div className="d-flex justify-content-center"><div className="number-circle number-circle-large">3</div></div>
							<h3 className="text-center mt-3"><FormattedMessage id="hot-flashes.how-it-works.cols.3.title" defaultMessage="Transform" /> </h3>
							<p>
								<FormattedMessage id="hot-flashes.how-it-works.cols.3.paragraph" defaultMessage="Transformation of thoughts, feelings, and behaviors result in greater well-being for you and the whole family." />
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col xs="auto">
							<Button size="lg" onClick={openModal}><FormattedMessage id="hot-flashes.join-button" defaultMessage="Get started" /></Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 py-md-5">
				<Container>
					<h1 className="text-center pb-4"><FormattedMessage id="hot-flashes.start-here.title" defaultMessage="Break the anxiety cycle" /></h1>
					<Row className="align-items-center">
						<Col xs="12" md="6">
							<div className="rounded bg-light shadow-sm p-3 mb-3">
								<p>
									The following image explains how our thoughts, emotions, and behaviors are <b>linked</b>.
								</p>
								<p>
									When your thoughts are negative, you feel negative, and you take negative action, which causes more negative thoughts, and the cycle continues.
								</p>
								<p className="pb-0">
									This <b>loop</b> becomes repetitive and increasingly negative until you might feel hopeless and even experience physical symptoms, like hot flashes or palpitations.
								</p>
							</div>
							<div className="rounded bg-light shadow-sm p-3 mb-3">
								Our 7-day self-help program helps you <b>find a way out</b> of this negative thinking loop. It provides practical ways to stop the loop, either by altering your thoughts or your behaviours.
							</div>
						</Col>
						<Col xs="12" md="6" className="d-flex justify-content-center mb-3 m-auto">
							<Img className="w-75" fluid={data?.cycle?.childImageSharp?.fluid}/>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 py-md-5">
				<Container>
					<h1 className="text-center pb-4"><FormattedMessage id="hot-flashes.testimonials.title" defaultMessage="Testimonials" /></h1>
					<Row className="justify-content-md-center">
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4, 5]).map((index) => <FaStar key={index} />)}</div>
								</div>
								<p className="text-muted"><FormattedMessage id="hot-flashes.testimonials.1" defaultMessage="The content is very easy to follow, it's clear and to the point. But the best part is you see that it works."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Maria, <cite title="Circle Member"><FormattedMessage id="hot-flashes.testimonials.member" defaultMessage="Housewife" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4, 5]).map((index) => <FaStar key={index} />)}</div>
								</div>
								<p className="text-muted"><FormattedMessage id="hot-flashes.testimonials.2" defaultMessage="Baushe have made me aware of how I can change the way I think about myself and start to see life in a positive way."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Leonor, <cite title="Circle Member"><FormattedMessage id="hot-flashes.testimonials.member" defaultMessage="Architect" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
					</Row>
					<Row className="align-items-center justify-content-md-center">
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4, 5]).map((index) => <FaStar key={index} />)}</div>
								</div>
								<p className="text-muted"><FormattedMessage id="hot-flashes.testimonials.3" defaultMessage="The program was like a daily moment of comfort and relax. The techniques seem so simple and I am impressed on how effective they are. It really helped me physically and mentally."/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Jennifer, <cite title="Circle Member"><FormattedMessage id="hot-flashes.testimonials.member" defaultMessage="High School Teacher" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
						<Col xs="12" md="6" lg="4" className="mb-3">
							<div className="d-flex flex-column h-100 justify-content-between p-3 rounded shadow">
								<div className="d-flex justify-content-between align-items-center">
									<FaQuoteLeft className="text-primary mb-2"/>
									<div className="text-success pb-2">{Array.from([1, 2, 3, 4]).map((index) => <FaStar key={index} />)}<FaStarHalfAlt /></div>
								</div>
								<p className="text-muted"><FormattedMessage id="hot-flashes.testimonials.4" defaultMessage="Sometimes I think too much about the future and it makes me feel anxious. That’s why I found the technique of distraction very helpful and I used it everyday. It's a helpful little step!"/> </p>
								<div className="blockquote-footer mb-2 align-self-end">
									Victoria, <cite title="Circle Member"><FormattedMessage id="hot-flashes.testimonials.member" defaultMessage="Lawyer" /></cite>
								</div>
								<FaQuoteRight className="text-primary align-self-end" />
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center align-items-center mt-3">
						<Col xs="12" md="6" lg="4" className="mb-3">
							<p className="text-center mb-0 text-md-left h5"><FormattedMessage id="hot-flashes.testimonials.try-free" defaultMessage="Try it yourself! It's free."/></p>
						</Col>
						<Col xs="12" md="6" lg="4" className="text-center text-md-right mb-3">
							<Button size="lg" onClick={openModal}><FormattedMessage id="hot-flashes.testimonials.join-now" defaultMessage="Get started"/></Button>
						</Col>
					</Row>
				</Container>
				<SignUpModal show={showModal} group="cbt-program" onHide={closeModal} redirect={false} onSuccess={handleSuccess} title={intl.formatMessage({ id: "hot-flashes.modal.title", defaultMessage: "Let's get started!" })} />
				<Modal show={showCalendly} onHide={() => setShowCalendly(false)}>
					<Modal.Header closeButton className="border-bottom-0">
						<Modal.Title><FormattedMessage id="hot-flashes.modal.step-2" defaultMessage="Well done!"/> </Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						<HiOutlineBadgeCheck className="display-2 mb-4 text-success" />
						<p>
							<b><FormattedMessage id="hot-flashes.modal.thank-you" defaultMessage="Thank you for signing up to our program!" /></b> <br/>
							<FormattedMessage id="hot-flashes.modal.message" defaultMessage="You will receive an email with confirmation shortly."/>
						</p>
						<Button className="my-4" size="lg" variant="outline-secondary" onClick={() => setShowCalendly(false)}>Close</Button>
					</Modal.Body>
				</Modal>
			</section>
		</Layout>
	)
}

export default Circles
